import { Controller } from "@hotwired/stimulus";
import tippy from 'tippy.js';

export default class extends Controller {
  static values = {
    content: String
  }

  initialize() {
    tippy(this.element, {
      theme: 'light',
      content: this.contentValue || "Content Missing",
      allowHTML: true,
      delay: [400, 0],
      maxWidth: 600,
    });
  }

}