import { Controller } from "@hotwired/stimulus"

/* This controller allows you to scroll to a target element on the page. 
 * Unfortunately if the page uses infinite scroll the element may not be on the page yet
 * So we scroll to the 'load-more' element and wait for the next page to render and check again 
 */
export default class extends Controller {
  static values = {
    target: String,
  }

  initialize() {
    this.targetFound = false;
  }

  connect() {
    if (this.targetValue && this.targetValue.length > 0) {
      document.addEventListener("page:render", ((event) => {
        if (!this.targetFound) {
          this.scrollToTarget();
        }
      }))
      this.scrollToTarget();      
    }
  }

  scrollToTarget() {
    const target = document.getElementById(this.targetValue);
    if (target) {
      this.targetFound = true;
      setTimeout(() => target.scrollIntoView({ behavior: 'smooth' }), 0);
      // target.scrollIntoView({ behavior: 'smooth' });
    }
  }

}