import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  static targets = ["submit", "input"]

  inputTargetConnected(input) {
    this.checkSelection()
  }

  inputTargetDisconnected(input) {
    this.checkSelection()
  }

  checkSelection() {
    let checked = this.inputTargets.filter((input) => input.checked)
    
    this.submitTargets.forEach((submit) => {
      if (checked.length > 0) {
        submit.removeAttribute("disabled")
      } else {
        submit.setAttribute("disabled", "disabled")
      }
    })
  }

}