import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  
  static values = { 
    show: Boolean,
  }

  connect() {
    if (this.showValue) {
      setTimeout(() => {
        this.element.classList.add("active")
      }, 100)
    }
  }

  show = (event) => {
    lock(document.body)
    document.dispatchEvent(new Event('document:lock'));
    this.element.classList.add("active")
  }

  hide = () => {
    this.element.classList.remove("active")
    unlock(document.body)
    document.dispatchEvent(new Event('document:unlock'));
  }
}