import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {

  // initialize () {
  //   this.onUpdate = this.onUpdate.bind(this)
  // }

  connect () {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: ".handle",
      // onUpdate: this.onUpdate,
      forceFallback: true,
      onChoose: function (event) {
        document.documentElement.classList.add("draggable-cursor");
      },
      onUnchoose: function (event) {
        document.documentElement.classList.remove("draggable-cursor");
      },
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  // async onUpdate ({ item, newIndex }) {
  //   if (!item.dataset.sortableUpdateUrl) return

  //   const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

  //   const data = new FormData()
  //   data.append(param, newIndex + 1)

  //   return await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  // }

  // onUpdate (event) {
  //   console.log("update", event)
  // }

}