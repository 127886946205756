import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.element.addEventListener("change", this.resize)
    this.resize()
  }

  resize = (e) => {
    var selectedOption = this.element.options[this.element.selectedIndex];
    var textWidth = this.getTextWidth(selectedOption.text, window.getComputedStyle(selectedOption).font);

    this.element.style.width = (textWidth + 25) + "px";
  }

  getTextWidth = (text, font) => {
    // Create a temporary canvas element to measure text width
    var canvas = document.createElement("canvas");
    document.body.appendChild(canvas);
    var context = canvas.getContext("2d");
    context.font = font;

    // Measure the width of the text
    var width = context.measureText(text).width;

    // Clean up and remove the temporary canvas element
    document.body.removeChild(canvas);

    return width;
  }
}