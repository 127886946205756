import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  options = {
    threshold: 1
  }

  connect() {
    this.createObserver();
    document.dispatchEvent(new CustomEvent("page:render"))
  }

  appear(entry) {
    this.element.innerHTML = "Loading ..."
    this.element.click()
    this.disabled = true    
    this.element.classList.add("is-loading")
  }

  createObserver() {
    let observer;
  
    let options = {
      threshold: 0
    };
    
    observer = new IntersectionObserver(entries => this.handleIntersect(entries), options);
    observer.observe(this.element);
  }

  handleIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.appear(entry)
      }
    });
  }
}

