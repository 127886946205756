import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable", "toggleStatus", "label" ]
  
  static values = {
    labelClose: { type: String, default: "Show Less" },
    labelOpen: { type: String, default: "Show More" }
  }

  initialize() {
    this.open = false
  }

  toggle (event) {
    event.stopPropagation()

    this.open = !this.open
    this.labelTargets.forEach(t => t.innerHTML = this.open ? this.labelCloseValue : this.labelOpenValue)
    this.toggleStatusTargets.forEach(t => t.classList.toggle("open", this.open))
    this.toggleableTargets.forEach(t => t.classList.toggle("open", this.open))
  }

}
