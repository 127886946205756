import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggleable", "toggleStatus" ]
  static values = { open: Boolean }

  initialize() {
    this.setStyle = this.setStyle.bind(this);
  }

  connect() {
    window.addEventListener('resize', this.setStyle) 
    this.setStyle()
    setTimeout(() => {
      this.setStyle()
    }, 0)
  }

  disconnect() {
    window.removeEventListener('resize', this.setStyle) 
  }

  toggle (event) {
    event.stopPropagation()
    this.openValue = !this.openValue
    this.toggleStatusTargets.forEach(t => t.classList.toggle("open", this.openValue))
    this.setStyle()
  }

  setStyle () {
    this.toggleableTargets.forEach(t => {
      const heightSize = this.openValue ? t.scrollHeight : 0
    
      t.style.overflow = 'hidden';
      t.style.transitionProperty = 'height';
      t.style.height = `${heightSize}px`;
      t.style.transitionDuration = '300ms';
    })
  }

}
