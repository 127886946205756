import { Controller } from "@hotwired/stimulus"

/* For Fixed Positoin elements such as the selection bar on the search pages,
 * When a modal opens and the body locks the content will shift to the right by 15px/2 due to the scrollbar dissapearing
 * So here we observe this event and adding padding to the element similar to the main body lock function 
 * which adds the padding to the HTML element*/
export default class extends Controller {

  connect() {
    document.addEventListener('document:lock', this.copyStyleFromHTML.bind(this));
    document.addEventListener('document:unlock', this.copyStyleFromHTML.bind(this));
    this.copyStyleFromHTML();
  }

  disconnect() {
    document.removeEventListener('document:lock', this.copyStyleFromHTML);
    document.removeEventListener('document:unlock', this.copyStyleFromHTML);
  }

  copyStyleFromHTML () {
    this.element.style.overflow = document.documentElement.style.overflow
    this.element.style.boxSizing = document.documentElement.style.boxSizing
    this.element.style.paddingRight = document.documentElement.style.paddingRight
  }

}