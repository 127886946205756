import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["table", "body"]


  connect() {
    // Listen for any DOM changes to this element
    this.observer = new MutationObserver(this.check.bind(this))

    // Start observing the target node for configured mutations
    this.observer.observe(this.bodyTarget, { childList: true, subtree: true })
  }

  disconnect() {
    // Disconnect the observer when the controller is disconnected
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  check(mutations) {
    this.tableTarget.dataset.tableRowsValue = this.element.children.length
    if (this.bodyTarget.children.length == 0) {
      Turbo.renderStreamMessage("<turbo-stream action='refresh'></turbo-stream>")
    }
  }

 }
