import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  
  static targets = ["input"]
  static values = { url: String }

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener("input", e => e.preventDefault()); // Prevent form submission on input
    });
  }

  /* Fetch form */
  async search(event) {
    event.preventDefault();
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  debounceSearch(event) {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
        this.search(event);
      }, 250)
  }

  urlWithQueryString() {
    return `${this.urlValue}?${this.queryString()}`;
  }

  queryString() {
    const params = new URLSearchParams();

    this.inputTargets.forEach((input) => {
      params.append(input.name, input.value);
    });

    return params.toString();
  }

}