import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "wrapper", "trigger", "popover" ]
  static values = { position: String }
  
  connect() {
    document.addEventListener('turbo:before-cache', () => {
      this.hide();
    })
  }

  trigger(event) {
    this.contextModalTarget.classList.toggle("active")
  }
  
  show(event) {
    let popover = this.popoverTarget;
    let trigger = this.triggerTarget;
    let position = this.positionValue;

    if (popover.classList.contains("active") || this.timeout) {
      return;
    }

    this.timeout = setTimeout(function() {
      const triggerRect = trigger.getBoundingClientRect()
      const popoverRect = popover.getBoundingClientRect()

      let left = (triggerRect.width - popoverRect.width)/2;
      let top = -1 * popoverRect.height; 

      left = Math.max(left, -1*triggerRect.x + 5) // don't go off the left side of the screen

      if (position == "right") {
        top = -1 * (popoverRect.height - triggerRect.height)/2;
        left = triggerRect.width + 5;
      }

      popover.style.left = `${left}px`;
      popover.style.top = `${top}px`;
      popover.classList.add("active");
    }, 500);
  }

  hide(event) {
    clearTimeout(this.timeout);
    this.timeout = null;
    this.popoverTarget.classList.remove("active");
  }  
}
