import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["value"]

  copy(event) {
    event.preventDefault()

    // If there is no value provided we assume it's the current URL
    if (!this.valueTarget.value) {
      this.valueTarget.value = window.location.href
    }

    this.valueTarget.type = 'text';
    this.valueTarget.select()
    document.execCommand("copy")
    this.valueTarget.type = 'hidden';

    document.dispatchEvent(new CustomEvent('notification:show', { detail: { value: "Link Copied to Clipboard" } }))
  }
}
