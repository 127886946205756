import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["role", "languages"]

  disconnect() {
    this.roleTarget.removeEventListener("change", this.checkRole)
  }

  connect() {
    this.roleTarget.addEventListener("change", this.checkRole)
    this.checkRole()
  }

  checkRole = (e) => {
    if (this.roleTarget.value == "translator") {
      this.languagesTarget.classList.remove("hidden")
    } else {
      this.languagesTarget.classList.add("hidden")
    }
  }

}