import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  download(event) {
    event.preventDefault();
    this.disable();

    fetch(this.element.href)
      .then(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '')

        response.blob().then(blob => {
          // Create a Blob URL for the file, use this in a temporary link element, trigger a click on this element and then remove it again.
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = filename;
          link.click();
          link.remove();
          URL.revokeObjectURL(blobUrl);

          this.enable();
        })
      })
      .catch(error => {
        console.error('Error:', error);
        alert("An error occurred while downloading the file.")
        this.enable();
      });

  }

  enable = () => {
    this.element.disabled = false;
    this.element.classList.remove("loading");
  }

  disable = () => {
    this.element.disabled = true;
    this.element.classList.add("loading");
  }

}
