import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static values = { url: String }

  connect() {
    // console.log("Form controller connected", this.urlValue)
  }

  submit(event) {
    this.element.requestSubmit()
  }

  async fetchForm() {
    // console.log("fetchForm", this.urlWithQueryString())
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    let url = this.urlValue || this.element.action;
    return `${url}${url.includes("?") ? "&" : "?"}${this.queryString()}`;
  }

  queryString() {
    const form = new FormData(this.element);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      params.append(name, value);
    }

    return params.toString();
  }
  
}
