import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "form", "input", "wordOptions", "submit"]
  static values = {
    autofocus: Boolean
  }

  connect() {
    this.inputTarget.addEventListener('input', this.check.bind(this))
    this.formTarget.addEventListener('submit', this.submit.bind(this))
    
    if (this.autofocusValue || this.inputTarget.value.length > 0) {
      document.addEventListener('turbo:before-stream-render', this.handleStreamRender.bind(this));
    }
    this.check();

    document.addEventListener('modal:show', () => this.enableInput.bind(this));
    document.addEventListener('modal:hide', () => this.disableInput.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.handleStreamRender);
    document.removeEventListener('modal:show', this.enableInput);
    document.removeEventListener('modal:hide', this.disableInput);
  }

  handleStreamRender(event) {
    // The problem is that the concept broadcast refresh will re-focus the reference language input field
    // So we'll just listen for the stream render event and focus the input field after a refresh
    // Problem is that there is no stream-render or after stream render event so we'll just use a timeout

    // we'll focus the input field after a turbo stream refresh render
    // otherwise the focus will jump to the reference language form field on concept broadcast refresh
    // we'll disable the input field when the modal is shown, to avoid the focus jumping to the next form field
    // after a modal submit. The browser seems keen to do this.
    if (event.target.action == "refresh") {
      this.focus();
      setTimeout(() => this.focus(), 0)
      setTimeout(() => this.focus(), 100)
      setTimeout(() => this.focus(), 200)
      setTimeout(() => this.focus(), 300)
    }

    setTimeout(() => this.focus(), 0)
  }

  disableInput() {
    this.inputTarget.disabled = true
  }

  enableInput() {
    this.inputTarget.disabled = false
  }

  check(event) {
    if (this.inputTarget.value.length == 0) {
      this.wordOptionsTarget.classList.remove("active")
      this.submitTarget.disabled = true
    } else {
      this.wordOptionsTarget.classList.add("active")
      this.submitTarget.disabled = false
    }
  }

  focus = () => {
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length);
  }

  /* We want enter to submit the form, but we don't want the browser to jump to the next form
   * field, so we prevent the default action and blur the input field. and then submit the form. */
  submit(event) {
    document.activeElement.blur()
  }

}