import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "form", "input", "display", "submit", "databases", "priorities", "modalSubmit"]
  static values = {
    mode: String,
  }

  connect() {
    this.inputTarget.addEventListener('input', this.check.bind(this))
    // this.formTarget.addEventListener('submit', this.submit.bind(this))
    this.check();
    if (this.modeValue == "edit") {
      setTimeout(() => this.edit(), 0)
    } else {
      setTimeout(() => this.display(), 0)
    }
  }

  check(event) {
    if (this.inputTarget.value.length == 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }

  edit() {
    this.displayTarget.classList.add("disabled")
    this.formTarget.classList.remove("disabled")
    this.databasesTarget.classList.add("disabled")
    this.prioritiesTargets.forEach(t => t.classList.add("disabled"))
    this.modalSubmitTargets.forEach(t => t.disabled = true)
    this.focus();
  }

  display() {
    this.displayTarget.classList.remove("disabled")
    this.formTarget.classList.add("disabled")
    this.databasesTarget.classList.remove("disabled")
    this.prioritiesTargets.forEach(t => t.classList.remove("disabled"))
    this.modalSubmitTargets.forEach(t => t.disabled = false)
  }

  focus() {
    this.inputTarget.focus();
    this.inputTarget.setSelectionRange(this.inputTarget.value.length, this.inputTarget.value.length);
  }

}