import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["on", "off"]

  connect() {
  }

  toggle(event) {
    this.onTargets.forEach(target => target.classList.toggle("active"))
    this.offTargets.forEach(target => target.classList.toggle("active"))
  }

 }
