import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static values = {
    url: String,
    param: String,
    type: {type: String, default: "turbo"},
  }
  
  connect() {
    this.element.addEventListener('change', this.change.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('change', this.change)
  }

  change(event) {
    this.updateUrl(event)
  }

  updateUrl(event) {
    if (this.typeValue === "turbo") {
      get(this.urlValue, {
        query: { 
          [this.paramValue]: event.target.selectedOptions[0].value,
        },
        responseKind: "turbo-stream",
      })

      // update the current URL with the new urlValue
      const params = new URLSearchParams(location.search);
      params.set(this.paramValue, event.target.selectedOptions[0].value);
      window.history.replaceState({}, '', `${this.urlValue}?${params}`)
    } else {
      // fallback to default behavior
      Turbo.visit(`${this.urlValue}?${this.paramValue}=${event.target.selectedOptions[0].value}`) //#, {frame: "_top"})
      // window.location = `${this.urlValue}?${this.paramValue}=${event.target.selectedOptions[0].value}`
    }
  }

}