import { Controller } from "@hotwired/stimulus"
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  static targets = ["form"]

  connect() {
    document.addEventListener('overlay:show', this.show);
    document.addEventListener('overlay:hide', this.hide);
    window.addEventListener('popstate', this.handlePopstate.bind(this));
  }

  disconnect() {
    document.removeEventListener('overlay:show', this.show);
    document.removeEventListener('overlay:hide', this.hide);
  }

  handlePopstate(event) {
    this.hide(false);
  }

  formTargetConnected(form) {
    form.addEventListener("turbo:submit-end", this.listener)
  }

  formTargetDisconnected(form) {
    form.removeEventListener("turbo:submit-end", this.listener)
  }

  submit() {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  listener = (e) => {
    const fetchResponse = e.detail.fetchResponse
    if(fetchResponse) {
      if(e.detail.success) {
        this.hide()
        // document.querySelector("body").body.hideForm(e)
        // document.body.classList.remove("show-modal")
        // setTimeout(() => unlock(document.body), 300)
      }
    }
  }

  show = () => {
    // document.getElementById("modal").innerHTML = ""
    this.element.classList.add("active")
    history.pushState({ modalOpen: true }, '', '');
    lock(document.body)
    document.dispatchEvent(new Event('document:lock'));
  }

  hide = (enableHistory=true) => {
    this.element.classList.remove("active");
    if (enableHistory) {
      history.back();
    }
    setTimeout(() => {
      unlock(document.body);
      document.dispatchEvent(new Event('document:unlock'));
      document.getElementById("modal").innerHTML = "";
    }, 300)
  }

}